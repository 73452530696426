@import url('https://fonts.googleapis.com/css2?family=Cinzel&display=swap');

nav {
    position: absolute;
    top: 0;
}
.navbar-brand {
    margin: 0;
    font-family: 'Cinzel', serif;
}

.navbar-toggler {
    border: none;
}

.nav-container {
    padding: 2.5vw 15vw 2.5vw 15vw;
    margin: 0;
    display: flex;
    vertical-align: bottom;
    list-style: none;
}

.nav-item {
    font-size: 1.5vw;
    text-align: right;
    display: inline-block;
}

.nav-item-left {
    flex: 70%
}

.main-logo{
    float: left;
    height: 3vw;
}

.nav-item-right {
    flex: 30%;
    display: inline-block;
    text-align: right;
    font-size: 1.5vw;
    margin-top: 1vw;
}

.nav-link {
    text-decoration: none;
    color: #ffffff !important;
}


/* Responsive layout - makes a one column-layout instead of a two-column layout */
@media (max-width: 768px) {
    .main-logo{
        height: 4.6vw;
    }
    .nav-item-right {
        font-size: 3vw;
    }
  }
@media (max-width: 540px) {
    .main-logo{
        height: 4.6vw;
    }
    .nav-container {
        padding: 5vw 4vw 2.5vw 4vw;
    }
    .nav-item-right {
        font-size: 3vw;
    }
  }