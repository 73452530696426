@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;600&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Catamaran:wght@200&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;600;800&display=swap');

body {
  margin: 0;
  font-family: 'Open Sans', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

h1 {
  font-family: 'Inter', sans-serif;
  font-size: 3.5vw !important;
  font-weight: 800 !important;
}

h2 {
  font-family: 'Inter', sans-serif;
  font-size: 1.75rem !important;
  font-weight: 600 !important;
  margin-bottom: 1.75vw !important;
}

p {
  font-family: 'OpenSans', sans-serif;
  font-size: 1.25vw;
}

button {
  border: none;
  border-radius: 20px !important;
  padding: 1vw 2vw;
  font-size: 1.5vw !important;
  background-color: rgb(181,76,46);
  color: #fff;
  margin: 2vw auto 3.5vw auto !important;
}


/* Responsive layout */
@media (max-width: 1024px) {
  h1 {
    font-size: 4.3vw !important;
  }
  
  h2 {
    font-size: 1.5rem !important;
  }
  p {
    font-size: 1rem;
  }
  button {
    font-size: 3.0vw !important;
    padding: 2.0vw 4.0vw;
  }
}
@media (max-width: 768px) {
  h1 {
    font-size: 2.5rem !important;
  }
  
  h2 {
    font-size: 2.0rem !important;
  }
  p {
    font-size: 1.4rem;
  }
  button {
    font-size: 3.0vw !important;
    padding: 2.0vw 4.0vw;
  }
}
@media (max-width: 540px) {
  h1 {
    font-size: 1.75rem !important;
  }
  
  h2 {
    font-size: 1.35rem !important;
  }
  p {
    font-size: 1rem;
  }
  button {
    font-size: 3.0vw !important;
    padding: 2.0vw 4.0vw;
  }
}